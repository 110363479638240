.single-message-span {
    display: flex;
    margin-bottom: 20px;
}

.single-message-p {
    min-width: 20px;
    max-width: 50%;
    border-radius: 15px;
    padding: 5px 10px;
    margin: 2px;
    color: white;
}

.dm-input-form {
    position: fixed; 
    bottom: 1px; 
    display: flex; 
    justify-content: center;
}