.dm-tab {
    width: clamp(2rem 30px 10%);
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    
}

.chat-icon {
    position: relative;
    float: left;
    margin-top: 20px;
}
.dm-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgb(100, 170, 250);
}

.chat-messages-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(100, 170, 250);
    border-left : 1px solid blue;
    position: relative;

    overflow: scroll;
}

.chat-message-controls {
    display: none;
}
.chat-message-controls:hover {
    display: block;
    cursor: pointer;
}
.single-message-p:hover + .chat-message-controls {
    display: block;
    cursor: pointer;

}

/* .dm-input-form {
    position: fixed;
    bottom: 2px;
} */