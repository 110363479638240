.sidebar {
    height: 80vh;
    width: 20%;
    padding:0;
    margin:0;
    float: left;
    position: fixed;
    top: 10%;
    display: flex;
    flex-direction: column;
    background-color: rgb(0, 126, 251);
    border-radius: 10px;
    box-shadow: 10px 5px 20px  rgb(165, 209, 248);
    z-index: 100;
}

.closed {
    background-color: inherit;
    height: 2rem;
    width: 2rem;
    box-shadow: none;
}

.sidebar-content {
    border-bottom: 1px solid black;
    padding: 3px;
}

.close-content {
    border: 1px solid black;
    display: none;
}