.nav-pane {
    text-align: center;
    width: 100vw;
    height: 100%;
}

.nav-link {
    color: black;
    margin: 0 5px;
    font-weight: 700;
    font-size: 1.1rem;
}

.nav-link:hover {
    color: rgb(42, 42, 252);
    font-size: 1.2rem;
}

.logout {
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    background-color: transparent;
    text-decoration: underline;
    margin: 0;
}

.user-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 2.5rem;
}

.avatar {
    min-height: 100px;
    margin-top: 5px;
    border-radius: 50%;
}

.user-nav-greetings {
    grid-column: 2;
    margin: 0;
    height: fit-content;
    font-size: 2.5rem;
}

.marquee-scroller {
    margin: 0;
    font-size: 1rem;
    font-weight: 800;
}

.qr-link {
    margin: 10px;
    text-decoration: none;
    font-weight: 650;
    cursor: pointer;
}