.media-player-shell {
    height: 500px;
    width: 500px;
    margin: 10px auto;
    border: 2px solid black;
    position: relative;
}

.playlist-items-display {
    width: 20%;
    height: 100%;
    border: 1px solid green;
    background-color: blueviolet;
}
