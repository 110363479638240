/* html body #root {
  min-height: 100vh;
} */

#root {
  min-height: 100vh;
}

.App {
  text-align: center;
  background-color: rgb(100, 170, 250);
  height: 100vh;
  overflow: scroll;
}

.navigation {
  height: 15vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: rgb(100, 170, 250);
}