.home-song-display {
    /* display: flex;
    flex-direction: row; */
}


.auido-track-container {
    display: flex;
    /* background-color: blueviolet; */
    margin: 3px;
}

.audio-tag-proper {
    height: 25px;
}

.audio-track-details {
    display: flex;
    flex-direction: row;
}

.audio-track-details-info {
    margin: 0 5px;
}