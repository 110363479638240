.comment-container {
    height: fit-content;
    margin-bottom: 10px;
    background-color: rgb(205, 223, 255);
}

.comments-section {
    display: flex;
    flex-direction: column-reverse;
    height: 175px;
    margin: .2%;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    overflow: scroll;
}

.comment-header {
    margin: 0;
}

.comment-username {
    display: inline-block;
    margin: 0;
    font-weight: 700;
}

.textarea {
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: inherit;
    padding: 1px 6px;
    display: block;
    width: 50%;
    overflow: hidden;
    min-height: 20px;
    line-height: 20px;
    margin: 0 auto;
}

.textarea[contenteditable]:empty::before {
    content: "Who Won?";
    color: gray;
  }

.public-text[contenteditable]:empty::before {
    content: "Beez Knees or aux please?";
    color: gray;
  }

  .comment-controls {
    display: none;
}
.comment-controls:hover {
    display: block;
    cursor: pointer;
}
.comment-string:hover + .comment-controls {
    display: block;
    cursor: pointer;
}