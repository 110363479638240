
.latest-videos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
}

.latest-video-list {
    margin: 10px 5px;
    text-align: start;
    align-items: center;
    border-radius: 10px;
    /* box-shadow: 3px 3px 15px 1px  rgb(165, 209, 248); */
    /* padding: 5px; */
}