.moveable {
    margin: 50px;
}

.table {
    border-collapse: collapse;
}

.video-container-video {
    position: absolute;
    top: 16%;
}

/* .day-name {
    border: 1px solid black;
    width: 75px;
    height: 25px;
}

.day-number {
    border: 1px solid black;
    width: 75px;
    height: 75px;
} */